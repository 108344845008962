import request from '@/utils/request'
import qs from 'qs'

const api = {
  add: '/workOrder/add',
  pager: '/workOrder/pager',
  list: '/workOrderMsg/list',
  addWorkOrderMsg: '/workOrderMsg/add',
  closeWorkOrder: '/workOrder/close'
}

export default api

export function getAddworkOrder (data) {
  return request({
    url: api.add,
    method: 'post',
    data
  })
}

export function getworkOrder (data) {
  return request({
    url: api.pager,
    method: 'post',
    data
  })
}

export function getWorkOrderMsg (data) {
  return request({
    url: api.list,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: qs.stringify(data)
  })
}

export function addWorkOrderMsg (data) {
  return request({
    url: api.addWorkOrderMsg,
    method: 'post',
    data
  })
}

export function closeWorkOrder (data) {
  return request({
    url: api.closeWorkOrder,
    method: 'post',
    data
  })
}
